<template>
  <div class="w-full h-full flex flex-col bg-gray-100">
    <div class="w-2/3 h-full flex flex-col mx-auto bg-white px-4 py-2 grow">
      <div class="flex flex-col shrink w-full">
        <div
          class="flex flex-row w-full cursor-pointer hover:bg-gray-100 p-2"
          @click.stop.prevent="showFilters = !showFilters"
          >
          <span class="grow font-bold">Filters</span>
          <v-icon v-if="showFilters">mdi-menu-up-outline</v-icon>
          <v-icon v-else>mdi-menu-down-outline</v-icon>
        </div>
        <div v-if="showFilters" class="flex flex-col px-2 space-y-1 text-sm">
          <div class="flex flex-row items-center space-x-2">
            <span>Folders:</span>
            <note-folder-selector-menu></note-folder-selector-menu>
            <v-chip-group row>
              <v-chip small close>Text</v-chip>
              <v-chip small close>Text</v-chip>
              <v-chip small close>Text</v-chip>
            </v-chip-group>
          </div>
          <div class="flex flex-row items-center space-x-2">
            <span>&nbsp;&nbsp;Labels:</span>
            <label-selector-menu
              :selected-labels="selectedLabels">
            </label-selector-menu>
            <label-list
              :labels="selectedLabels"
              >
            </label-list>
          </div>
          <div class="flex flex-row items-center space-x-2">
            <span>&nbsp;&nbsp;&nbsp;Types:</span>
            <note-type-selector-menu
              :selected-types="selectedTypes"
              >
            </note-type-selector-menu>
            <note-type-list :types="selectedTypes"></note-type-list>
          </div>

        </div>
      </div>
      <p class="my-2 font-bold px-2 w-full">Search Results</p>
      <div
        class="w-full overflow-y-auto"
        :style="{
          height: `calc(100vh - ${ showFilters ? '270px' : '150px' })`
        }">
        <template v-if="viewMode == 'list'">
          <notes-list :notes="noteRecords"></notes-list>
        </template>
        <template v-else>
          <notes-grid :notes="noteRecords"></notes-grid>
        </template>
        <div class="w-full flex items-center my-8">
          <v-btn
            v-if="hasMoreNotes"
            outlined
            class="mx-auto"
            @click.stop.prevent="loadMoreNotes(false)"
            >
            Click to load more...
          </v-btn>
          <p v-else class="mx-auto">No more data to load</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import NotesList from '@/components/NotesList.vue'
import NotesGrid from '@/components/NotesGrid.vue'
import LabelSelectorMenu from '@/components/LabelSelectorMenu.vue'
import NoteTypeSelectorMenu from '@/components/NoteTypeSelectorMenu.vue'
import NoteFolderSelectorMenu from '@/components/NoteFolderSelectorMenu.vue'
import MakeLoadMoreMixin from '@/api/make-loadmore-mixin'
import LabelList from '@/components/LabelList.vue'
import NoteTypeList from '@/components/NoteTypeList.vue'

export default {
  name: 'SearchView',

  components: {
    NotesList,
    NotesGrid,
    LabelList,
    NoteTypeList,
    LabelSelectorMenu,
    NoteTypeSelectorMenu,
    NoteFolderSelectorMenu
  },

  mixins: [
    MakeLoadMoreMixin({ name: 'Note' }),
  ],

  data() {
    return {
      subjects: [],
      labels: [],
      types: [],
      filters: {
        subjects: [],
        labels: [],
        types: []
      },
      showFilters: false,
      selectedFolders: [],
      selectedTypes: [],
      selectedLabels: []
    }
  },

  computed: {
    viewMode() {
      return this.$store.state.notesViewMode
    },

    searchKeywords() {
      return this.$store.state.searchKeywords
    },

    noteFilters() {
      let filters = []
      if (this.selectedLabels.length > 0) {
        filters.push({
          op: 'containedIn',
          key: 'labels',
          value: this.selectedLabels
        })
      }

      if (this.selectedTypes.length > 0) {
        filters.push({
          op: 'containedIn',
          key: 'type',
          value: this.selectedTypes
        })
      }

      if (this.selectedFolders.length > 0) {
        filters.push({
          op: 'containedIn',
          key: 'parent',
          value: this.selectedFolders
        })
      }

      if (_.isString(this.searchKeywords) && _.trim(this.searchKeywords)) {
        filters.push({
          op: 'contains',
          key: 'title',
          value: _.trim(this.searchKeywords)
        })
      }

      return filters
    }
  }
}
</script>